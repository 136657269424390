export default class GetterProxy {
  constructor(record) {
    this.record = record

    return new Proxy(this, {
      get(obj, prop) {
        if (prop in obj) {
          return obj[prop]
        } else if (prop in record) {
          return record[prop]
        }

        return undefined
      }
    })
  }
}
