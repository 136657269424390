import apollo from '../utils/apollo'
import publishedContestsQuery from '../queries/publishedContests.gql'
import GiveawayGetter from '../services/giveaway-getter'

export default {
  methods: {
    async fetchGiveawaysByIdTokens(idTokenList, cacheSeconds = 10) {
      const giveaways = await this.fetchGiveaways({ idTokens: idTokenList }, cacheSeconds)
      return giveaways
    },
    async fetchGiveawayByIdToken(idToken, cacheSeconds = 10) {
      const list = await this.fetchGiveaways({ idTokens: [idToken] }, cacheSeconds)

      if (list.length) {
        return list[0]
      }

      return null
    },
    async fetchGiveawaysByHost(hostId, maxEndedDays = 90, cacheSeconds = 10) {
      const giveaways = await this.fetchGiveaways({ hostId, maxEndedDays }, cacheSeconds)
      return giveaways
    },
    async fetchGiveaways(searchParams, cacheSeconds = 10) {
      let giveawayList = []

      try {
        const {
          data: { publishedContests }
        } = await apollo.client.query({
          query: publishedContestsQuery,
          variables: searchParams,
          context: {
            headers: {
              'x-cache-hint': `public, max-age=${cacheSeconds}`
            }
          }
        })

        giveawayList = publishedContests.map((contest) => new GiveawayGetter(contest))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      return giveawayList
    }
  }
}
