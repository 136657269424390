import moment from 'moment'
import padStart from 'lodash/padStart'
import GetterProxy from './getter-proxy'

export default class GiveawayGetter extends GetterProxy {
  get giveaway() {
    return this.record
  }

  get id() {
    return this.giveaway.id
  }

  get idToken() {
    return this.giveaway.idToken
  }

  get isSuspended() {
    return this.giveaway.visibility === 'SUSPENDED'
  }

  get isEnded() {
    if (this.iwpOnly && this.iwpForceEnded) {
      return true
    }

    return moment().isAfter(moment(this.giveaway.expiration))
  }

  get isUpcoming() {
    return moment().isBefore(moment(this.giveaway.startTime))
  }

  get isDraft() {
    return this.giveaway.draftState === 'DRAFT'
  }

  get isActive() {
    if (this.iwpOnly && this.iwpForceEnded) {
      return false
    }

    return !this.isUpcoming && !this.isEnded && !this.isDraft && !this.isSuspended
  }

  get allowIwp() {
    return !!this.giveaway.iwpEnabled
  }

  get iwpOnly() {
    return this.allowIwp && !!this.giveaway.iwpOnly
  }

  get iwpForceEnded() {
    return this.allowIwp && !!this.giveaway.iwpForceEnded
  }

  get startsIn() {
    const now = moment()
    // Adding a 2s buffer here to give server time to switch
    // the giveaway live and account for clock descrepancies.
    const start = moment(this.giveaway.startTime).add(2, 's')
    const difference = start.diff(now, 'seconds')
    return Math.max(difference, 0)
  }

  get counterFormattedStartsIn() {
    const start = moment.duration({ seconds: this.startsIn })

    return {
      days: Math.floor(start.asDays()),
      hours: start.hours(),
      minutes: padStart(start.minutes(), 2, 0),
      seconds: padStart(start.seconds(), 2, 0)
    }
  }

  get endsIn() {
    const now = moment()
    const end = moment(this.giveaway.expiration)
    const difference = end.diff(now, 'seconds')
    return Math.max(difference, 0)
  }

  get counterFormattedEndsIn() {
    const end = moment.duration({ seconds: this.endsIn })

    return {
      days: Math.floor(end.asDays()),
      hours: end.hours(),
      minutes: padStart(end.minutes(), 2, 0),
      seconds: padStart(end.seconds(), 2, 0)
    }
  }

  formatStartDate(format) {
    return moment(this.giveaway.startTime).format(format)
  }

  formatEndDate(format) {
    return moment(this.giveaway.expiration).format(format)
  }
}
