import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { createHttpLink } from 'apollo-link-http'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import ApolloClient from 'apollo-client'
import surfConfig from '../config/surf'

class Apollo {
  constructor() {
    const httpLinkOptions = { uri: surfConfig.apollo.graphql }

    this.cache = new InMemoryCache()
    this.link = ApolloLink.from([
      createPersistedQueryLink({ useGETForHashedQueries: true }),
      new RetryLink(),
      createHttpLink(httpLinkOptions)
    ])
  }

  get client() {
    return new ApolloClient({
      cache: this.cache,
      link: this.link,
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache'
        }
      }
    })
  }
}

export default new Apollo()
